<template>
  <div class="home">
    <Row>
      <Form
        style="display: flex; justify-content: end"
        ref="formInline"
        :model="fromData"
        inline
        :label-width="10"
        label-position="right"
      >
        <FormItem>
          <DatePicker
            @on-change="fromData.startTime = $event"
            :value="fromData.startTime"
            format="yyyy-MM-dd"
            type="date"
            placeholder="开始时间"
            style="width: 180px"
          >
          </DatePicker>
        </FormItem>
        <FormItem>
          <DatePicker
            @on-change="fromData.endTime = $event"
            :value="fromData.endTime"
            format="yyyy-MM-dd"
            type="date"
            placeholder="结束时间"
            style="width: 180px"
          >
          </DatePicker>
        </FormItem>
        <FormItem>
          <Select
            @on-change="searchList()"
            v-model="fromData.timeFlag"
            placeholder="选择日期"
            clearable
            style="width: 120px"
          >
            <Option
              v-for="(item, index) in timeType"
              :key="index"
              :value="item.value"
            >
              {{ item.lable }}</Option
            >
          </Select>
        </FormItem>
        <FormItem>
          <Select
            v-model="type"
            placeholder="统计类型"
            clearable
            style="width: 120px"
          >
            <Option
              v-for="(item, index) in statisticsType"
              :key="index"
              :value="item.value"
            >
              {{ item.lable }}</Option
            >
          </Select>
        </FormItem>
        <FormItem>
          <Select
            @on-change="searchList()"
            v-model="fromData.villageId"
            placeholder="全部小区"
            clearable
            style="width: 120px"
          >
            <Option
              v-for="(item, index) in villageFormItemSelectList"
              :key="index"
              :value="item.v_village_id"
            >
              {{ item.v_village_name }}</Option
            >
          </Select>
        </FormItem>
        <FormItem>
          <Select
            @on-change="searchList()"
            v-model="fromData.vElevatorCode"
            placeholder="全部电梯"
            clearable
            style="width: 120px"
          >
            <Option
              v-for="(item, index) in elevatorSelectList"
              :key="index"
              :value="item.v_elevator_code"
            >
              {{ item.v_elevator_code }}</Option
            >
          </Select>
        </FormItem>
        <Button
          type="primary"
          shape="circle"
          icon="ios-search"
          @click="searchList()"
          >搜索
        </Button>
      </Form>
    </Row>
    <div
      v-show="type == 1"
      class="echart-box"
      id="fault"
      :style="`width:100%;height:600px;zoom:${zoom};transform:scale(${
        1 / zoom
      });transform-origin:0 0`"
    ></div>
    <div
      v-show="type == 2"
      class="echart-box"
      id="maintenance"
      :style="`width:100%;height:600px;zoom:${zoom};transform:scale(${
        1 / zoom
      });transform-origin:0 0`"
    ></div>
    <div
      v-show="type == 3"
      class="echart-box"
      id="Uncivilized"
      :style="`width:100%;height:600px;zoom:${zoom};transform:scale(${
        1 / zoom
      });transform-origin:0 0`"
    ></div>
    <div 
      v-show="type == 4" 
      class="echart-box" 
      id="faultWarn" 
      :style="
        `width:100%;height:600px;zoom:${zoom};transform:scale(${1 /
          zoom});transform-origin:0 0`
      "></div>
  </div>
</template>
<script>
import {
  getUncivilizedTrend,
  getMaintenanceTrend,
  getRepairTrend,
  getFaultTrend,
} from '@/api/ststistics/index.js'
import {
  searchVillageSelectList,
  getElevatorsStatus,
} from '@/api/home/index.js'

export default {
  data() {
    return {
      villageFormItemSelectList: [],
      elevatorSelectList: [],
      fromData: {
        startTime: this.formatDate(-30) + '',
        endTime: this.formatDate(0) + '',
        timeFlag: '00',
        villageId: '',
        elevatorCode: '',
      },
      type: '3',
      timeType: [
        {
          value: '00',
          lable: '日',
        },
        {
          value: '11',
          lable: '周',
        },
        {
          value: '22',
          lable: '月',
        },
      ],
      statisticsType: [
        // {
        //   value: '1',
        //   lable: '急修工单',
        // },
        // {
        //   value: '2',
        //   lable: '维保工单',
        // },
        {
          value: '3',
          lable: '不文明行为',
        },
        {
          value: '4',
          lable: '物联报警',
        },
      ],

      elevatorData: { villageId: '', vProjectId: '' },
      zoom: 1,

      option: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999',
            },
          },
        },
        legend: {
          data: ['电动车入梯趋势', '反复阻挡门趋势'],
          
          textStyle: {
            fontSize: 16, //字体大小
            color: 'red', //字体颜色
          },
        },
        xAxis: [
          {
            type: 'category',
            data: ['外围分布', '瑞金总院'],
            axisLabel: {
              interval: 0,
              rotate: 30,
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            name: '',
            min: 0,
            interval: 50,
            name: '电动车入梯次数',
            axisLabel: {},
          },
          {
            type: 'value',
            name: '',
            min: 0,
            name: '反复阻挡门次数',
            axisLabel: {},
          },
        ],
        series: [
          {
            name: '全部',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' ml'
              },
            },
            data: [6, 124],
          },
          {
            name: '困人(比率)',
            type: 'line',
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value + ' °C'
              },
            },
            data: [0, 0.01773],
          },
        ],
      },
      option2: {
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
          axisLabel: {},
        },
        tooltip: {
          trigger: 'axis',
        },

        yAxis: {
          type: 'value',
          name: '维保次数',
          axisLabel: {},
        },
        series: [
          {
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: 'line',
            areaStyle: {},
          },
        ],
      },
      option3: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
          },
        },
        legend: {
          data: ['急修工单趋势', '困人工单趋势'],
        },
        xAxis: [
          {
            type: 'category',
            data: ['外围分布', '瑞金总院'],
            axisLabel: {
              interval: 0,
              rotate: 30,
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            name: '',
            min: 0,
            interval: 50,
            name: '急修工单次数',
            axisLabel: {},
          },
          {
            type: 'value',
            name: '',
            min: 0,
            name: '困人工单次数',
            axisLabel: {},
          },
        ],
        series: [
          {
            name: '全部',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' ml'
              },
            },
            data: [6, 124],
          },
          {
            name: '困人(比率)',
            type: 'line',
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value + ' °C'
              },
            },
            
            data: [0, 0.01773],
          },
        ],
      },
      option4: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999',
            },
          },
        },
        legend: {
          data: ['急修工单趋势', '困人工单趋势'],
        },
        xAxis: [
          {
            type: 'category',
            data: ['外围分布', '瑞金总院'],
            axisLabel: {
              interval: 0,
              rotate: 30,
            },
          },
        ],
        yAxis: [
          {
            type: 'value',
            name: '',
            min: 0,
            interval: 50,
            name: '急修工单次数',
            axisLabel: {},
          },
          {
            type: 'value',
            name: '',
            min: 0,
            name: '困人工单次数',
            axisLabel: {},
          },
        ],
        series: [
          {
            name: '全部',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + ' ml'
              },
            },
            data: [6, 124],
          },
          {
            name: '困人(比率)',
            type: 'line',
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value + ' °C'
              },
            },
            data: [0, 0.01773],
          },
        ],
      },
      option5: {
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
          axisLabel: {},
        },
        tooltip: {
          trigger: 'axis',
        },

        yAxis: {
          type: 'value',
          name: '物联报警数',
          axisLabel: {},
        },
        series: [
          {
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: 'line',
            areaStyle: {},
          },
        ],
      },
    }
  },
  computed: {
    theme() {
      return this.$store.getters.theme
    },
  },
  mounted() {
    const width =
      document.documentElement.clientWidth || document.body.clientWidth
    this.zoom = 1 / (width / 1920)
    window.addEventListener('resize', () => {
      const width =
        document.documentElement.clientWidth || document.body.clientWidth
      this.zoom = 1 / (width / 1920)
    })
    searchVillageSelectList({ projectId: '8089373891892543488' }).then(
      (res) => {
        this.villageFormItemSelectList = res.info
      }
    )
    this.searchList()
  },

  watch: {
    'fromData.villageId': function (newObj, OldObj) {
      if (newObj) {
        this.elevatorData.villageId = newObj
        getElevatorsStatus(this.elevatorData).then((res) => {
          this.elevatorSelectList = res.info
        })
      } else {
        this.elevatorData.vElevatorCode = ''
        this.elevatorSelectList = []
      }
    },
    type: function (newObj) {
      if (newObj == 1) {
        this.getRepairTrend()
      } else if (newObj == 2) {
        this.getMaintenanceTrend()
      } else if(newObj == 3) {
        this.getUncivilizedTrend()
      } else if(newObj == 4) {
        this.getFaultTrend()
      }
    },

    theme(newVal, oldVal) {
      if (newVal != oldVal) {
        this.searchList()
      }
    },
  },

  methods: {
    searchList() {
      this.type == 1
        ? this.getRepairTrend()
        : this.type == 2
        ? this.getMaintenanceTrend()
        : this.type == 3
        ? this.getUncivilizedTrend()
        : this.getFaultTrend()
    },

    /** 不文明行为趋势 */
    getUncivilizedTrend() {
      getUncivilizedTrend(this.fromData).then((res) => {
        let myChart = this.$echarts.init(document.getElementById('Uncivilized'))
        if (res.info) {
          this.option.xAxis[0].data = res.info.categories.map((item) => {
            return {
              value: item,
              textStyle: {
                color: this.theme == 'dark' ? '#fff' : '',
              },
            }
          })
          let arr = []
          let arr2 = []

          res.info.series.forEach((item) => {
            arr.push(item.name)
            if (item.type == 'column') {
              arr2.push({
                name: item.name,
                type: 'bar',
                tooltip: {
                  valueFormatter: function (value) {
                    return value
                  },
                },
                data: item.data,
              })
            } else {
              arr2.push({
                name: item.name,
                type: 'line',
                tooltip: {
                  valueFormatter: function (value) {
                    return value
                  },
                },
                itemStyle: {
                    normal: {
                      color: 'orange',
                      borderColor: '#ffffff', //拐点边框颜色
                    },
                  },
                yAxisIndex: 1,
                data: item.data,
              })
            }
          })
          this.option.yAxis.forEach((item) => {
            item.axisLabel.color = this.theme == 'dark' ? '#fff' : ''
          })
          // this.option.legend.data = arr
          this.option.series = arr2
          myChart.setOption(this.option)
        }
      })
    },
    /** 维保工单趋势 */
    getMaintenanceTrend() {
      getMaintenanceTrend(this.fromData).then((res) => {
        if (res.info) {
          let myChart = this.$echarts.init(
            document.getElementById('maintenance')
          )
          this.option2.xAxis.data = res.info.categories.map((item) => {
            return {
              value: item,
              textStyle: {
                color: this.theme == 'dark' ? '#fff' : '',
              },
            }
          })
          this.option2.series[0].data = res.info.series[0].data
          this.option2.yAxis.axisLabel.color =
            this.theme == 'dark' ? '#fff' : ''
          myChart.setOption(this.option2)
        }
      })
    },
    /** 维保工单趋势 */
    getFaultTrend() {
      getFaultTrend(this.fromData).then((res) => {
        if (res.info) {
          let myChart = this.$echarts.init(
            document.getElementById('faultWarn')
          )
          this.option5.xAxis.data = res.info.categories.map((item) => {
            return {
              value: item,
              textStyle: {
                color: this.theme == 'dark' ? '#fff' : '',
              },
            }
          })
          this.option5.series[0].data = res.info.series[0].data
          this.option5.yAxis.axisLabel.color =
            this.theme == 'dark' ? '#fff' : ''
          myChart.setOption(this.option5)
        }
      })
    },
    /** 急修工单趋势 */
    getRepairTrend() {
      getRepairTrend(this.fromData).then((res) => {
        console.log(res, 'res')
        let myChart = this.$echarts.init(document.getElementById('fault'))
        if (res.info) {
          this.option3.xAxis[0].data = res.info.categories.map((item) => {
            return {
              value: item,
              textStyle: {
                color: this.theme == 'dark' ? '#fff' : '',
              },
            }
          })

          let arr = []
          let arr2 = []

          res.info.series.forEach((item) => {
            arr.push(item.name)
            if (item.type == 'column') {
              arr2.push({
                name: item.name,
                type: 'bar',
                tooltip: {
                  valueFormatter: function (value) {
                    return value
                  },
                },
                data: item.data,
              })
            } else {
              arr2.push({
                name: item.name,
                type: 'line',
                tooltip: {
                  valueFormatter: function (value) {
                    return value
                  },
                },
                // itemStyle: {
                //     normal: {
                //       color: 'orange',
                //       borderColor: '#ffffff', //拐点边框颜色
                //     },
                //   },
                yAxisIndex: 1,
                data: item.data,
              })
            }
          })
          this.option3.yAxis.forEach((item) => {
            item.axisLabel.color = this.theme == 'dark' ? '#fff' : ''
          })
          // this.option.legend.data = arr
          this.option3.series = arr2
          myChart.setOption(this.option3)
        }
      })
    },
  },
}
</script>
<style scoped>
.home {
  padding: 10px 30px 0 10px;
}
</style>